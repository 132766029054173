import { StrictMode, CSSProperties } from "react";
import { Root, createRoot } from "react-dom/client";
import { StyleSheetManager, createGlobalStyle } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";

// Hack for Buffer polyfill
import { Buffer } from "buffer";
import IframeContainer from "components/IframeContainer";

enum WidgetMessages {
    CloseWidget = "CloseWidget",
    OpenWidget = "OpenWidget",
    SetCoupon = "SetCoupon",
    SetDiscount = "SetDiscount",
    SetTrial = "SetTrial",
    SetEmail = "SetEmail",
    SetExternalSubscriptionId = "SetExternalSubscriptionId",
}

if (typeof window !== "undefined") {
    window.Buffer = window.Buffer || Buffer;
}

// Need to "reset" the host (shadowRoot) base styles, otherwise they inherit styling from the parent DOM
const HostStyle = createGlobalStyle`
    :host {
        all: initial;
        display: block;
        background: transparent;
        display: flow-root;
        margin: 0;
        font-family: 'Open Sans', 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-weight: 400;
        line-height: 1.4;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        z-index: 0;
    }
`;

type CheckoutWidgetAppProps = {
    target: HTMLElement;
    entityId: string;
    cartEnabled?: boolean;
    env: string;
    onSuccess?: () => void;
    onClose?: () => void;
    externalSubscriptionId?: string;
    coupon?: string;
    trial?: number;
    discount?: number;
    email?: string;
    defaultSpendingCap?: number;
    externalReferenceId?: string;
    minimumBalanceRequired?: number;
};

const CheckoutWidgetApp = ({
    target,
    entityId,
    cartEnabled = false,
    env,
    onSuccess,
    onClose,
    externalSubscriptionId,
    coupon,
    trial,
    discount,
    email,
    defaultSpendingCap,
    externalReferenceId,
    minimumBalanceRequired,
}: CheckoutWidgetAppProps) => {
    return (
        <StyleSheetManager
            shouldForwardProp={shouldForwardProp}
            target={target}
        >
            <>
                <HostStyle />
                <IframeContainer
                    entityId={entityId}
                    env={env}
                    cartEnabled={cartEnabled}
                    externalSubscriptionId={externalSubscriptionId}
                    onSuccess={onSuccess}
                    onClose={onClose}
                    coupon={coupon}
                    trial={trial}
                    discount={discount}
                    email={email}
                    defaultSpendingCap={defaultSpendingCap}
                    externalReferenceId={externalReferenceId}
                    minimumBalanceRequired={minimumBalanceRequired}
                />
            </>
        </StyleSheetManager>
    );
};

// Make sure the web3onboard popup is on top
let web3onboardGlobalRootStyleInjected = false;
const web3onboardGlobalRootStyle = (zIndex: number) => {
    if (web3onboardGlobalRootStyleInjected) return;
    document.head.insertAdjacentHTML(
        "beforeend",
        `<style>:root { --onboard-modal-z-index: ${zIndex}!important; }</style>`
    );
    web3onboardGlobalRootStyleInjected = true;
};

// This will make our app available to any javascript enabled app
type InitializeLoopCheckoutProps = {
    entityId: string;
    itemId?: string; // DEPRECATED
    containerStyle?: CSSProperties;
    cartEnabled?: boolean;
    env?: string;
    onSuccess?: () => void;
    onClose?: () => void;
    externalSubscriptionId?: string;
    coupon?: string;
    trial?: number;
    discount?: number;
    email?: string;
    defaultSpendingCap?: number;
    refId?: string;
    minimumBalanceRequired?: number;
};

// Assume that a refId may be set for a specific user ("Case 1"), but also possibly for a specific item ("Case 2")
//      Case 1: `externalReferenceId` is accepted as an optional argument to `initializeLoopCheckoutWidget`
//      Case 2: `openWidget` accepts `externalReferenceId` as an optional 2nd argument (this will take priority over Case 1, if both are)
//
// Also note: `setRefId` was not created, as the existing setter functionality is not current ready for use
// This note is to server a reminder that setRefId should be created when those features are fixed & released

const initializeLoopCheckoutWidget = ({
    entityId,
    itemId, // DEPRECATED
    containerStyle = { zIndex: 999999 },
    cartEnabled = false,
    env = "production",
    onSuccess,
    onClose,
    externalSubscriptionId,
    coupon,
    trial,
    discount,
    email,
    defaultSpendingCap,
    refId: externalReferenceId,
    minimumBalanceRequired,
}: InitializeLoopCheckoutProps) => {
    // Root instance
    let root: Root | undefined;

    // Deprecated `itemId` as of version 0.2.4
    if (itemId) {
        console.warn(
            `The property "itemId" is no longer an argument of "initializeLoopCheckoutWidget()". Pass the relevant itemId string as an argument to "openWidget()" to load the necessary data.`
        );
    }

    // Remove DOM container
    const clearDom = () => {
        const previousContainer: HTMLDivElement | null = document.querySelector(
            "#loop-checkout-widget"
        );

        if (!previousContainer) return;

        previousContainer.parentNode?.removeChild(previousContainer);
    };

    // Attach DOM & Create React App
    const createWidget = async () => {
        // Add zIndex on top
        if (containerStyle.zIndex) {
            // @ts-ignore
            web3onboardGlobalRootStyle(containerStyle.zIndex + 1);
        }

        // Destroy existing container
        clearDom();

        // Create the container
        const container = document.createElement("div");
        container.setAttribute("id", "loop-checkout-widget");

        // Add styles
        if (containerStyle) {
            let stylesKey = Object.keys(containerStyle);
            stylesKey.forEach((key) => {
                // @ts-ignore
                container.style[key] = containerStyle[key];
            });
        }

        // Append to body
        document.body.appendChild(container);

        // Attach shadowRoot
        const shadow = container.attachShadow({
            mode: "open",
        });

        if (!container.shadowRoot) return Promise.reject();

        // Render app
        root = createRoot(container.shadowRoot);

        // create a slot where we will attach the StyleSheetManager
        const styleSlot = document.createElement("div");

        // append the renderIn element inside the styleSlot
        shadow.appendChild(styleSlot);

        root.render(
            <StrictMode>
                <CheckoutWidgetApp
                    target={styleSlot}
                    entityId={entityId}
                    env={env}
                    onSuccess={onSuccess}
                    onClose={onClose}
                    cartEnabled={cartEnabled}
                    externalSubscriptionId={externalSubscriptionId}
                    coupon={coupon}
                    trial={trial}
                    discount={discount}
                    email={email}
                    defaultSpendingCap={defaultSpendingCap}
                    externalReferenceId={externalReferenceId}
                    minimumBalanceRequired={minimumBalanceRequired}
                />
            </StrictMode>
        );

        return Promise.resolve();
    };

    // Destroy / Unmount react app
    const destroyWidget = () => {
        // Close the widget
        closeWidget();

        // Unmount the app
        setTimeout(() => {
            if (root) {
                root.unmount();
                root = undefined;
            }

            // Remove DOM container
            clearDom();
        }, 200);
    };

    // Open widget does the actual instantiation of the plugin
    const openWidget = async (itemId: string, options?: { refId?: string }) => {
        if (!itemId) {
            throw new Error(`openWidget must be passed a valid itemId string`);
        }

        if (!root) await createWidget();

        window.postMessage(
            {
                type: WidgetMessages.OpenWidget,
                itemId,
                externalReferenceId: options?.refId,
            },
            window.location.origin
        );
    };

    const closeWidget = () => {
        window.postMessage(
            { type: WidgetMessages.CloseWidget },
            window.location.origin
        );
    };

    const setCoupon = (coupon: string) => {
        window.postMessage(
            { type: WidgetMessages.SetCoupon, coupon },
            window.location.origin
        );
    };

    const setTrial = (trial: number) => {
        window.postMessage(
            { type: WidgetMessages.SetTrial, trial },
            window.location.origin
        );
    };

    const setDiscount = (discount: number) => {
        window.postMessage(
            { type: WidgetMessages.SetDiscount, discount },
            window.location.origin
        );
    };

    const setEmail = (email: string) => {
        window.postMessage(
            { type: WidgetMessages.SetEmail, email },
            window.location.origin
        );
    };

    const setExternalSubscriptionId = (externalSubscriptionId: string) => {
        window.postMessage(
            {
                type: WidgetMessages.SetExternalSubscriptionId,
                externalSubscriptionId,
            },
            window.location.origin
        );
    };

    return {
        buildVersion: process.env.BUILD_VERSION,
        destroyWidget,
        openWidget,
        closeWidget,
        setCoupon,
        setDiscount,
        setTrial,
        setEmail,
        setExternalSubscriptionId,
    };
};

// This implements the default behavior from styled-components v5
function shouldForwardProp(propName: string, target: any) {
    if (typeof target === "string") {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
}

export default initializeLoopCheckoutWidget;
