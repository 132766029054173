import * as S from "./styles";
import { useState, useRef, FunctionComponent } from "react";
import Close from "components/icons/Close";
import LoadingLogo from "components/LoadingLogo";
import { EnvType } from "types";

enum WidgetMessages {
    CloseWidget = "CloseWidget",
    OpenWidget = "OpenWidget",
    SetCoupon = "SetCoupon",
    SetTrial = "SetTrial",
    SetDiscount = "SetDiscount",
    SetEmail = "SetEmail",
    SetExternalSubscriptionId = "SetExternalSubscriptionId",
}
interface IframeContainerProps {
    entityId: string;
    cartEnabled?: boolean;
    env: string;
    onSuccess?: () => void;
    onClose?: () => void;
    externalSubscriptionId?: string;
    coupon?: string;
    trial?: number;
    discount?: number;
    email?: string;
    defaultSpendingCap?: number;
    externalReferenceId?: string;
    minimumBalanceRequired?: number;
}

// @shanevc Is `coupon` a per-item parameter? Or per-app? (if per-item, then needs to come in via postMessage, not with the container)

export enum IframeMessages {
    CheckoutComplete = "CheckoutComplete",
    SetCoupon = "SetCoupon",
    SetDiscount = "SetDiscount",
    SetTrial = "SetTrial",
    SetEmail = "SetEmail",
    SetExternalSubscriptionId = "SetExternalSubscriptionId",
    CloseWidget = "CloseWidget",
}

const IframeContainer: FunctionComponent<IframeContainerProps> = ({
    entityId,
    cartEnabled = false,
    env,
    onSuccess,
    onClose,
    externalSubscriptionId,
    coupon,
    trial,
    discount,
    email,
    defaultSpendingCap,
    externalReferenceId: globalExternalReferenceId,
    minimumBalanceRequired,
}) => {
    const iframeOrigin =
        env === EnvType.LOCAL
            ? `http://localhost:3002`
            : env === EnvType.DEV
            ? `https://develop.checkout.loopcrypto.xyz`
            : env === EnvType.STAGE
            ? `https://staging.checkout.loopcrypto.xyz`
            : env === EnvType.DEMO
            ? `https://demo.checkout.loopcrypto.xyz`
            : `https://checkout.loopcrypto.xyz`;

    const parentOrigin = window.location.origin;

    const [iframeSrc, setIFrameSrc] = useState<string>(``);
    const [iframeOpen, setIframeOpen] = useState<boolean>(false);
    const [iframeLoading, setIframeLoading] = useState<boolean>(true);
    const checkoutIframeRef = useRef<HTMLIFrameElement>(null);

    const closeWidget = () => {
        setIframeOpen(false);
        if (!onClose) return;
        onClose();
    };

    const openWidget = (
        itemIdForIframe: string,
        options?: { externalReferenceId?: string }
    ) => {
        if (!itemIdForIframe) return;

        // Prevent user accidentally adding query params to the itemId
        //   @kirkas how can this happen? Is this required?
        if (itemIdForIframe.includes("?")) {
            itemIdForIframe = itemIdForIframe.split("?")[0];
        }

        // If an externalReferenceId was passed via user-exposed `openWidget`, use that, else use the externalReferenceId passed at initialization
        const refId = options?.externalReferenceId || globalExternalReferenceId;

        let src = `${iframeOrigin}/${entityId}/${itemIdForIframe}?embed=true&cartEnabled=${cartEnabled.toString()}`;

        // @kirkas Why can't a src have coupon, email, and externalSubscriptionId at the same time?
        if (externalSubscriptionId) {
            src = `${src}&sub=${externalSubscriptionId}`;
        }
        if (coupon) {
            src = `${src}&coupon=${coupon}`;
        }
        if (trial) {
            src = `${src}&trial=${trial}`;
        }
        if (discount) {
            src = `${src}&discount=${discount}`;
        }
        if (email) {
            src = `${src}&email=${email}`;
        }
        if (defaultSpendingCap) {
            src = `${src}&defaultSpendingCap=${defaultSpendingCap}`;
        }
        if (minimumBalanceRequired) {
            src = `${src}&minimumBalanceRequired=${minimumBalanceRequired}`;
        }
        if (refId) {
            src = `${src}&refId=${encodeURIComponent(refId)}`;
        }
        setIFrameSrc(src);
        setIframeOpen(true);
    };

    const handleOnLoad = (event: React.MouseEvent<HTMLIFrameElement>) => {
        setIframeLoading(false);
    };

    const handleOnClose = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        closeWidget();
    };

    const processMessage = (event: MessageEvent) => {
        // Message from the parent
        if (event.origin === parentOrigin) {
            if (event.data.type === WidgetMessages.CloseWidget) {
                closeWidget();
            }

            if (
                event.data.type === WidgetMessages.OpenWidget &&
                event.data.itemId
            ) {
                openWidget(event.data.itemId, {
                    externalReferenceId: event.data.externalReferenceId,
                });
            }

            if (event.data.type === WidgetMessages.SetCoupon) {
                if (
                    checkoutIframeRef.current &&
                    checkoutIframeRef.current.contentWindow
                ) {
                    checkoutIframeRef.current.contentWindow.postMessage(
                        {
                            type: IframeMessages.SetCoupon,
                            coupon: event.data.coupon,
                        },
                        parentOrigin
                    );
                }
            }

            if (event.data.type === WidgetMessages.SetTrial) {
                if (
                    checkoutIframeRef.current &&
                    checkoutIframeRef.current.contentWindow
                ) {
                    checkoutIframeRef.current.contentWindow.postMessage(
                        {
                            type: IframeMessages.SetTrial,
                            trial: event.data.trial,
                        },
                        parentOrigin
                    );
                }
            }

            if (event.data.type === WidgetMessages.SetDiscount) {
                if (
                    checkoutIframeRef.current &&
                    checkoutIframeRef.current.contentWindow
                ) {
                    checkoutIframeRef.current.contentWindow.postMessage(
                        {
                            type: IframeMessages.SetDiscount,
                            discount: event.data.discount,
                        },
                        parentOrigin
                    );
                }
            }

            if (event.data.type === WidgetMessages.SetExternalSubscriptionId) {
                if (
                    checkoutIframeRef.current &&
                    checkoutIframeRef.current.contentWindow
                ) {
                    checkoutIframeRef.current.contentWindow.postMessage(
                        {
                            type: IframeMessages.SetExternalSubscriptionId,
                            externalSubscriptionId:
                                event.data.externalSubscriptionId,
                        },
                        parentOrigin
                    );
                }
            }

            if (event.data.type === WidgetMessages.SetEmail) {
                if (
                    checkoutIframeRef.current &&
                    checkoutIframeRef.current.contentWindow
                ) {
                    checkoutIframeRef.current.contentWindow.postMessage(
                        {
                            type: IframeMessages.SetEmail,
                            email: event.data.email,
                        },
                        parentOrigin
                    );
                }
            }
        }

        // Message from the iframe checkout - checkout success
        if (event.origin === iframeOrigin) {
            if (event.data === IframeMessages.CheckoutComplete && onSuccess) {
                onSuccess();
            }
        }

        // Message from the iframe checkout - user closed the widget
        if (event.origin === iframeOrigin) {
            if (event.data === IframeMessages.CloseWidget) {
                closeWidget();
            }
        }
    };

    /* For various reasons, the eventlistener can't be in a single-run useEffect hook, or it won't get created correctly
    There are other various issues related to StrictMode and also garbage collection (removing the listener). A better
    solution will have to developed in the future, but this ref hack will assign the listener BEFORE the first render
    and not add it again unless this component is destroyed and recreated.
    */
    const listenerAdded = useRef(false);
    if (!listenerAdded.current) {
        window.addEventListener("message", processMessage);
        listenerAdded.current = true;
    }

    return (
        <S.ModalContainer iframeOpen={iframeOpen}>
            {iframeLoading && (
                <S.LoadingLogoContainer>
                    <LoadingLogo />
                </S.LoadingLogoContainer>
            )}

            <S.IframeContainer>
                <S.CheckoutControls>
                    <S.ClosePopoverLink href="#close" onClick={handleOnClose}>
                        <Close
                            fill="rgba(0,0,0,0.3)"
                            height="1.5rem"
                            width="1.5rem"
                        />
                    </S.ClosePopoverLink>
                </S.CheckoutControls>
                {iframeSrc && (
                    <S.CheckoutIframe
                        src={iframeSrc}
                        onLoad={handleOnLoad}
                        ref={checkoutIframeRef}
                        allow="ethereum; solana"
                    />
                )}
            </S.IframeContainer>
        </S.ModalContainer>
    );
};

export default IframeContainer;
