import { css, createGlobalStyle, styled } from "styled-components";

export const CheckoutIframe = styled.iframe`
    display: flow-root;
    width: 100%;
    height: 100%;
    border: none;
    top: 0;
    right: 0;
    bottom: 0;
`;

const iframeOpened = css`
    transform: translateX(0);
`;
const iframeClosed = css`
    transform: translateX(100%);
`;

const modalOpened = css`
    opacity: 1;
`;
const modalClosed = css`
    opacity: 0;
    pointer-events: none;
`;

export const LoadingLogoContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 4rem;
`;

export const IframeContainer = styled.div`
    height: 100vh;
    position: relative;
    width: 100%;
    position: absolute;
    right: 0;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    @media screen and (min-width: 600px) {
        max-width: 500px;
    }
`;

export const GlobalStyle = createGlobalStyle`
    body {
        overflow: hidden;
    }
`;

export const ModalContainer = styled.dialog<{ iframeOpen: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    padding: 2rem;
    margin: 0;
    background-color: rgba(0, 0, 20, 0.8);
    padding: 0;
    overflow: auto;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    ${({ iframeOpen }) => (iframeOpen ? modalOpened : modalClosed)}
    ${IframeContainer} {
        ${({ iframeOpen }) => (iframeOpen ? iframeOpened : iframeClosed)}
    }
`;

export const CheckoutControls = styled.div`
    @media screen and (max-width: 599px) {
        text-align: right;
        padding-right: 1rem;
        padding-top: 1rem;
        position: absolute;
        top: 0;
        right: 0;
    }
    @media screen and (min-width: 600px) {
        position: absolute;
        right: calc(100% + 3rem);
        top: 2rem;
    }
`;

export const ClosePopoverLink = styled.a`
    @media screen and (max-width: 599px) {
        color: #666;
        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    @media screen and (min-width: 600px) {
        color: #fff;
        display: inline-block;
        position: absolute;
        font-size: 0;
    }
`;
