import "./style.css";
import {
    ReactNode,
    useCallback,
    useState,
    useMemo,
    memo,
    useEffect,
} from "react";
import initializeLoopCheckoutWidget from "@loop-crypto/loop-checkout-widget";
import { EnvType } from "src/types";
import { nodeToString } from "src/utils/components";

const urlParams = new URLSearchParams(window.location.search);
const env = urlParams.get("env")?.toLowerCase() || `staging`;
const entity =
    urlParams.get("entity") || `36e8af7a-6ef5-4fb4-af09-9056df88daef`; // "Delegated"
const cartEnabled = urlParams.get("cartEnabled")?.toLowerCase() || undefined;
const externalSubscriptionId = urlParams.get("sub") || undefined;
const coupon = urlParams.get("coupon") || undefined;
const email = urlParams.get("email") || `test@loopcrypto.xyz`;
const refId = urlParams.get("refId") || undefined; // This is not available yet in this version
const trial = urlParams.get("trial") || undefined;
const discount = urlParams.get("discount") || undefined;
const items = urlParams.get("items")?.split(",") || [
    `30e1835b-ccfb-4405-8e0d-20c44723e226`,
    `18ec54c5-9e8a-4813-bbf8-7b699e7e2046`,
];
const spendingCap = urlParams.get("defaultSpendingCap") || undefined;
const minimumBalanceRequired =
    urlParams.get("minimumBalanceRequired") || undefined;

const useLogger = () => {
    const [log, setLog] = useState<ReactNode[]>([]);

    const toLog = useCallback(
        (message: ReactNode) => {
            console.log(nodeToString(message));
            setLog((prevLog) => [
                ...prevLog,
                <>
                    <time>({new Date().toLocaleTimeString("en-US")})</time>{" "}
                    {message}
                </>,
            ]);
        },
        [setLog]
    );

    return { log, toLog };
};

const Logger = memo(({ log }: { log: ReactNode[] }) => {
    return (
        <ul className="logger">
            {log.map((item, index) => (
                <li key={index} className="log">
                    {item}
                </li>
            ))}
        </ul>
    );
});

const App = () => {
    const { log, toLog } = useLogger();
    const [version, setVersion] = useState<string>();

    const { openWidget, buildVersion } = useMemo(() => {
        if (!Object.values(EnvType).includes(env as EnvType)) {
            toLog(
                <>
                    Invalid <code>env</code> parameter is set
                </>
            );
            return {};
        }
        toLog(
            <>
                called:
                <br />
                <code>
                    initializeLoopCheckoutWidget({String.fromCharCode(123)}
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>env: "{env}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>entityId: "{entity}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>email: "{email}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>cartEnabled: "
                    {cartEnabled}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>externalSubscriptionId: "
                    {externalSubscriptionId}", //"sub"
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>coupon: "{coupon}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>trial: "{trial}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>discount: "{discount}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>refId: "{refId}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>defaultSpendingCap: "
                    {spendingCap}",
                    <br />
                    <pre>{String.fromCharCode(9)}</pre>minimumBalanceRequired: "
                    {minimumBalanceRequired}",
                    <br />
                    {String.fromCharCode(125)})
                </code>
            </>
        );
        return initializeLoopCheckoutWidget({
            entityId: entity,
            email,
            env,
            cartEnabled,
            externalSubscriptionId,
            coupon,
            trial,
            discount,
            refId,
            defaultSpendingCap: spendingCap,
            minimumBalanceRequired,
            onSuccess: () =>
                toLog(
                    <>
                        <code>onSuccess</code> event fired
                    </>
                ),
            onClose: () =>
                toLog(
                    <>
                        <code>onClose</code> event fired
                    </>
                ),
        });
    }, [toLog]);

    useEffect(() => {
        if (!buildVersion) return;
        console.log(`Build version:`, buildVersion);
        setVersion(buildVersion);
    }, [buildVersion]);

    return (
        <>
            <header>
                <img
                    src="/img/loop-logo/loop-crypto-long.svg"
                    className="logo"
                    alt="Loop Crypto"
                />
                <h1 hidden>Loop Checkout Widget Demo</h1>
                <p className="version">
                    Build version: <code>{version}</code>
                </p>
            </header>
            <section>
                {!openWidget ? (
                    <>
                        <h1>Something went wrong</h1>
                        <p>
                            Check that the <code>env</code> parameter is set
                            correctly
                        </p>
                    </>
                ) : (
                    items.map((itemId) => (
                        <article key={itemId} className="item">
                            <button
                                onClick={() => {
                                    toLog(
                                        <>
                                            <code>openWidget("{itemId}")</code>{" "}
                                            called
                                        </>
                                    );
                                    openWidget(itemId);
                                }}
                                className="btn"
                            >
                                ItemId: {itemId}
                            </button>
                        </article>
                    ))
                )}
            </section>
            <section>
                <h2>Event log</h2>
                <Logger log={log} />
            </section>
        </>
    );
};

export default App;
