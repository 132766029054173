import { ReactNode } from "react";
import { renderToStaticMarkup } from "react-dom/server";

const nodeToString = (node: ReactNode): string => {
    const parser = new DOMParser();
    return (
        parser.parseFromString(renderToStaticMarkup(<>{node}</>), "text/html")
            .body.textContent || ``
    );
};

export { nodeToString };
